import React, { FC, useCallback } from 'react';

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Saved from '@components/icons/Saved';
import Gallery from '@components/icons/Gallery';
import { book } from '@routes/book';
import { useNavigate } from 'react-router-dom';
import { chatActions } from '@bus/chat/actions';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '@bus/profile/actions';
import { ThemeVariant } from '@bus/ui/typedefs';
import { uiActions } from '@bus/ui/actions';
import { getTheme } from '@bus/ui/selectors';
import Bedtime from '@components/icons/Bedtime';
import UserProfile from '@components/icons/UserProfile';
import Tutorial from '@components/icons/Tutorial';
import { getGuidance } from '@bus/guidance/selectors';
import { useIntercom } from 'react-use-intercom';
import { getProfile } from '@bus/profile/selectors';
import { shortTokensAmount } from '@helpers/shortTokensAmount';
import Support from '@components/icons/Support';
import { modalActions } from '@core/Modal/state/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
import { authActions } from '@bus/auth/actions';
import { optionsActions } from '@bus/options/actions';
import { projectsActions } from '@bus/projects/actions';
import ExitToApp from '@components/icons/ExitToApp';
import PaymentIcon from '@components/icons/PaymentIcon';

type AppBarTooltipProps = {};

export const AppBarTooltip: FC<AppBarTooltipProps> = () => {
  const { startTour, show } = useIntercom();
  const theme = useTheme();
  const themes = useSelector(getTheme);
  const guides = useSelector(getGuidance);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);

  const handleClickProfile = () => {
    dispatch(chatActions.setTooltipStatus(null));
    dispatch(profileActions.setDatasetsClosed());
    navigate(book.profile);
  };

  const handleClickPayments = () => {
    dispatch(chatActions.setTooltipStatus(null));
    dispatch(profileActions.setDatasetsClosed());
    navigate(book.payments);
  };

  const handleClickSaved = () => {
    dispatch(chatActions.setTooltipStatus(null));
    dispatch(profileActions.setDatasetsClosed());
    navigate(`${book.savedMessages}/0`);
  };

  const handleClickGallery = () => {
    dispatch(chatActions.setTooltipStatus(null));
    dispatch(profileActions.setDatasetsClosed());
    navigate(`${book.imageGallery}/0`);
  };

  const handleClickTutorial = useCallback(() => {
    if (themes === ThemeVariant.dark) {
      dispatch(uiActions.fillTheme(ThemeVariant.light));
    }
    if (guides) {
      dispatch(chatActions.setTooltipStatus(null));
      dispatch(profileActions.setDatasetsClosed());
      startTour(guides.results[0].tour_id);
    }
  }, [guides, themes]);

  const handleChange = () => {
    if (!themes) return;
    if (themes === ThemeVariant.dark) {
      dispatch(uiActions.fillTheme(ThemeVariant.light));
    } else {
      dispatch(uiActions.fillTheme(ThemeVariant.dark));
    }
  };

  const handleSupport = () => {
    dispatch(chatActions.setTooltipStatus(null));
    dispatch(profileActions.setDatasetsClosed());
    show();
  };

  const handleLogout = () => {
    dispatch(
      modalActions.modal({
        component: 'ConfirmOrCancel',
        forceClose: false,
        onCancel: () => {
          dispatch(profileActions.clearData());
          dispatch(createdImagesActions.clearData());
          dispatch(authActions.isAuthenticatedFalse());
          dispatch(optionsActions.clearOptions());
          dispatch(projectsActions.clearProjects());
          sessionStorage.removeItem('redirectTo');
          navigate(book.signIn);
        },
        onConfirm: () => dispatch(modalActions.closeModal('ConfirmOrCancel')),
        modalPayload: {
          title: 'Confirm Action',
          body: 'Are you sure you want to Log Out?',
        },
        cancelButton: {
          text: 'Log Out',
        },
        confirmButton: {
          text: 'Go back',
        },
      }),
    );
  };

  return (
    <List sx={styles.appBarTooltip}>
      {!!profile?.tokens && profile?.tokens > 0 && (
        <Box sx={styles.head}>
          <ListItem disablePadding>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={'8px 8px 8px 16px'}>
              <Typography variant={'body1'} color={'text.primary'} ml={'4px'}>
                CREATE: {shortTokensAmount(10, true, false)}
              </Typography>
            </Box>
          </ListItem>
        </Box>
      )}
      <ListItem disablePadding>
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          p={'8px 8px 8px 16px'}>
          <Box sx={styles.iconWrapper}>
            <Bedtime
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
            <Typography variant={'body1'} color={'text.primary'} ml={'10px'}>
              Dark mode
            </Typography>
          </Box>
          <Switch
            sx={styles.switcher}
            checked={themes === ThemeVariant.dark}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'User Profile'}>
        <ListItemButton onClick={handleClickProfile}>
          <Box sx={[styles.iconWrapper]}>
            <UserProfile fontSize={'small'} />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            User Profile
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Account'}>
        <ListItemButton onClick={handleClickPayments}>
          <Box sx={[styles.iconWrapper]}>
            <PaymentIcon
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Account
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Image Gallery'}>
        <ListItemButton onClick={handleClickGallery}>
          <Box sx={[styles.iconWrapper]}>
            <Gallery
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Image Gallery
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Saved Messages'}>
        <ListItemButton onClick={handleClickSaved}>
          <Box sx={[styles.iconWrapper]}>
            <Saved fontSize={'small'} htmlColor={theme.palette.text.primary} />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Saved Messages
          </Typography>
        </ListItemButton>
      </ListItem>
      <Divider sx={{ mx: '20px' }} />
      <ListItem disablePadding data-intercom-target={'Tutorial'}>
        <ListItemButton onClick={handleClickTutorial}>
          <Box sx={[styles.iconWrapper]}>
            <Tutorial
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Tutorial
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Tutorial'}>
        <ListItemButton className={'show'} onClick={handleSupport}>
          <Box sx={[styles.iconWrapper]}>
            <Support
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Support
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-intercom-target={'Logout'}>
        <ListItemButton onClick={handleLogout}>
          <Box sx={[styles.iconWrapper]}>
            <ExitToApp
              fontSize={'small'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
          <Typography variant={'body1'} color={'text.primary'}>
            Logout
          </Typography>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default AppBarTooltip;
