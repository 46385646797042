export const privateBook = {
  home: '/',
  chat: '/chat',
  profile: '/profile',
  profileEditing: '/profile-editing',
  notFound: '/not-found',
  savedMessages: '/saved-messages',
  imageGallery: '/image-gallery',
  forUnsubscribedUsers: '/forUnsubscribedUsers',
  storyTeller: '/story-teller',
  storyTellerHome: '/story-teller',
  metametrics: '/metametrics',
  metametricsCreating: '/metametrics-create',
  metametricsCard: '/metametrics',
  payments: '/account', // INJECT
};
