import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const PaymentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_198_450)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0998 7.37962C11.3158 6.83927 12.9431 6.52429 14.7005 6.52429C16.4578 6.52429 18.0851 6.83927 19.3012 7.37962C19.9082 7.64938 20.446 7.99004 20.8421 8.40628C21.2419 8.8264 21.5263 9.35769 21.5263 9.97468C21.5263 10.5917 21.2419 11.123 20.8421 11.5431C20.446 11.9593 19.9082 12.3 19.3012 12.5697C18.0851 13.1101 16.4578 13.4251 14.7005 13.4251C12.9431 13.4251 11.3158 13.1101 10.0998 12.5697C9.49268 12.3 8.95494 11.9593 8.55882 11.5431C8.15899 11.123 7.87458 10.5917 7.87458 9.97468C7.87458 9.35769 8.15899 8.8264 8.55882 8.40628C8.95494 7.99004 9.49268 7.64938 10.0998 7.37962ZM9.6454 9.44036C9.43025 9.66644 9.37458 9.84568 9.37458 9.97468C9.37458 10.1037 9.43025 10.2829 9.6454 10.509C9.86425 10.7389 10.2164 10.9801 10.7089 11.199C11.692 11.6358 13.1026 11.9251 14.7005 11.9251C16.2983 11.9251 17.7089 11.6358 18.692 11.199C19.1845 10.9801 19.5367 10.7389 19.7555 10.509C19.9707 10.2829 20.0263 10.1037 20.0263 9.97468C20.0263 9.84568 19.9707 9.66644 19.7555 9.44036C19.5367 9.21041 19.1845 8.96922 18.692 8.75038C17.7089 8.31353 16.2983 8.02429 14.7005 8.02429C13.1026 8.02429 11.692 8.31353 10.7089 8.75038C10.2164 8.96922 9.86425 9.21041 9.6454 9.44036Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.62466 9.22472C9.03887 9.22472 9.37466 9.5605 9.37466 9.97472V18.0759C9.37466 18.2041 9.42989 18.3829 9.64371 18.6085C9.86116 18.8379 10.2114 19.0791 10.7024 19.2981C11.6824 19.7354 13.0926 20.0263 14.7005 20.0263C16.3084 20.0263 17.7186 19.7354 18.6987 19.2981C19.1896 19.0791 19.5399 18.8379 19.7573 18.6085C19.9712 18.3829 20.0264 18.2041 20.0264 18.0759V9.97472C20.0264 9.5605 20.3622 9.22472 20.7764 9.22472C21.1906 9.22472 21.5264 9.5605 21.5264 9.97472V18.0759C21.5264 18.6902 21.2441 19.2203 20.8461 19.6403C20.4517 20.0564 19.9159 20.3975 19.3098 20.6679C18.0958 21.2096 16.4681 21.5263 14.7005 21.5263C12.933 21.5263 11.3052 21.2096 10.0912 20.6679C9.48512 20.3975 8.94932 20.0564 8.55497 19.6403C8.15698 19.2203 7.87466 18.6902 7.87466 18.0759V9.97472C7.87466 9.5605 8.21045 9.22472 8.62466 9.22472Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.2807 2.48594C11.2777 2.38806 13.2576 2.89769 14.9593 3.94787C15.3118 4.16541 15.4212 4.62751 15.2037 4.98C14.9861 5.33249 14.524 5.4419 14.1715 5.22436C12.7233 4.33059 11.0375 3.89834 9.33784 3.98495C9.32513 3.9856 9.3124 3.98593 9.29967 3.98593C7.69887 3.98593 6.2884 4.27672 5.30618 4.71426C4.81411 4.93346 4.4624 5.17484 4.24386 5.40464C4.02882 5.63076 3.9738 5.80926 3.9738 5.93631C3.9738 6.07053 4.03664 6.26691 4.29447 6.51854C4.55463 6.77245 4.96825 7.03414 5.53541 7.2684C5.91825 7.42653 6.10042 7.86508 5.94229 8.24792C5.78416 8.63076 5.34561 8.81293 4.96277 8.6548C4.28775 8.37598 3.68873 8.02334 3.24679 7.59203C2.80252 7.15843 2.4738 6.59871 2.4738 5.93631C2.4738 5.32076 2.75801 4.7904 3.15689 4.37096C3.55227 3.9552 4.08915 3.61431 4.6958 3.34407C5.90666 2.80467 7.5268 2.4882 9.2807 2.48594ZM8.62457 13.2753C9.03879 13.2753 9.37457 13.6111 9.37457 14.0253C9.37457 14.1535 9.4298 14.3323 9.64362 14.5579C9.86107 14.7874 10.2113 15.0285 10.7023 15.2475C11.6823 15.6848 13.0925 15.9757 14.7004 15.9757C16.3083 15.9757 17.7185 15.6848 18.6986 15.2475C19.1895 15.0285 19.5398 14.7874 19.7573 14.5579C19.9711 14.3323 20.0263 14.1535 20.0263 14.0253C20.0263 13.6111 20.3621 13.2753 20.7763 13.2753C21.1905 13.2753 21.5263 13.6111 21.5263 14.0253C21.5263 14.6397 21.244 15.1698 20.846 15.5897C20.4516 16.0058 19.9158 16.347 19.3098 16.6174C18.0958 17.159 16.468 17.4757 14.7004 17.4757C12.9329 17.4757 11.3051 17.159 10.0911 16.6174C9.48503 16.347 8.94923 16.0058 8.55488 15.5897C8.15689 15.1698 7.87457 14.6397 7.87457 14.0253C7.87457 13.6111 8.21036 13.2753 8.62457 13.2753Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.22385 5.18629C3.63806 5.18629 3.97385 5.52208 3.97385 5.93629V14.0375C3.97385 14.1717 4.03668 14.3681 4.29451 14.6197C4.55467 14.8736 4.9683 15.1353 5.53546 15.3695C5.9183 15.5277 6.10046 15.9662 5.94233 16.3491C5.7842 16.7319 5.34566 16.9141 4.96282 16.7559C4.2878 16.4771 3.68878 16.1245 3.24683 15.6932C2.80256 15.2596 2.47385 14.6998 2.47385 14.0375V5.93629C2.47385 5.52208 2.80963 5.18629 3.22385 5.18629Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.22385 9.23688C3.63806 9.23688 3.97385 9.57266 3.97385 9.98688C3.97385 10.1211 4.03668 10.3175 4.29451 10.5691C4.55467 10.823 4.9683 11.0847 5.53546 11.319C5.9183 11.4771 6.10046 11.9156 5.94233 12.2985C5.7842 12.6813 5.34566 12.8635 4.96282 12.7054C4.2878 12.4266 3.68878 12.0739 3.24683 11.6426C2.80256 11.209 2.47385 10.6493 2.47385 9.98688C2.47385 9.57266 2.80963 9.23688 3.22385 9.23688Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_450">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PaymentIcon;
