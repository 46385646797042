import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { couponActions } from '../../actions';
import {
  ConfirmCouponActionPayload,
  UpdateCouponActionPayload,
} from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* confirmCoupon({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ConfirmCouponActionPayload>) {
  try {
    yield effects.put(couponActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `coupons/${payload.uuid}/`, unsafe: true },
    ]);
    if (!response.ok) {
      yield effects.put(couponActions.fillError('Invalid code! Try another'));
      yield effects.put(couponActions.setIsConfirmed(true));
      yield throwError(response);
    }

    const data: UpdateCouponActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(couponActions.updateCoupon(data));
    yield effects.put(couponActions.fillResult('Success!'));
    yield effects.put(couponActions.setIsConfirmed(true));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(couponActions.stopFetching());
  }
}
