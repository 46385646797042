import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { couponActions } from '../../actions';
import { AddCouponActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { fetchSubscriptionPlans } from '@bus/subscription/saga/workers';
import { fetchProfile } from '@bus/profile/saga/workers';

export function* addCoupon({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<AddCouponActionPayload>) {
  try {
    yield effects.put(couponActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'users/coupons/', body },
    ]);

    if (!response.ok) {
      reject();

      yield effects.put(couponActions.setIsAddedCouponApproved('error'));
      yield throwError(response);
    }

    yield effects.put(couponActions.setIsAddedCouponApproved('success'));
    yield effects.call(fetchProfile);
    yield effects.call(fetchSubscriptionPlans);
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(couponActions.stopFetching());
  }
}
