import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionCoupon } from '@bus/profile/selectors';

type AmbassadorInformationWindowProps = {
  text: string;
  buttonLabel: string;
  tokens?: number;
};

export const AmbassadorInformationWindow: React.FC<
  ModalTypes.ModalComponentProps<AmbassadorInformationWindowProps>
> = ({ closeFn, text, buttonLabel, tokens }) => {
  const dispatch = useDispatch();
  const coupon = useSelector(getSubscriptionCoupon);

  const closeModal = () => {
    closeFn();
    dispatch(profileActions.finishOnboarding({ on_boarding: false }));
  };

  return (
    <Box sx={styles.trialInformationWindow}>
      <Box sx={styles.container}>
        <Typography variant={'h5'} color={'text.primary'}>
          Congratulations! The {coupon?.subscription_type.name} period has
          begun.
        </Typography>
        <Box sx={styles.wrapper}>
          <Typography variant={'subtitle2'} color={'text.primary'} mb={'10px'}>
            Possibilities:
          </Typography>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              Questions
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {coupon?.subscription_type.plan_limit}
            </Typography>
          </Box>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              Visualizations
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {coupon?.subscription_type.plan_visualize_limit}
            </Typography>
          </Box>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              Stories
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {coupon?.subscription_type.plan_storyteller_limit}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              Metametrics
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {coupon?.subscription_type.plan_metametric_limit}
            </Typography>
          </Box>
        </Box>
        <Typography variant={'body1'} color={'text.primary'} mt={'16px'}>
          {`We're giving you ${coupon?.subscription_type.name} subscription to use within ${coupon?.subscription_type.trial_period_days} days for an unforgettable experience with Sibyl.`}
        </Typography>
        <Button
          variant={'primary'}
          fullWidth
          sx={{ marginTop: '40px' }}
          onClick={closeModal}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default AmbassadorInformationWindow;
