import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import FinalForm from '@core/FinalForm';
import {
  schema,
  VisualAdjustmentsForm,
} from '@components/forms/VisualAdjustmentsForm';
import { useDispatch, useSelector } from 'react-redux';
import { projectsActions } from '@bus/projects/actions';
import { getVisualizePrompt } from '@bus/projects/selectors';
import { getOptions } from '@bus/options/selectors';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import {
  getIsVisualAdjustments,
  getProfile,
  getVisualizeLimit,
  getVisualizeText,
} from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import { modalActions } from '@core/Modal/state/actions';
import { SubPlansCode } from '@bus/subscription/typedefs';
import { LimitExhaustedContent } from '@components/modals/LimitExhausted';
import { useTokensDisclaimer } from '../../../hooks/useTokensDisclaimer';
import { wsActions } from '@bus/ws/actions';

type VisualAdjustmentsProps = {
  messageId: number;
  limit: number;
  projectId: number;
  chatId: number;
};

export const VisualAdjustments: React.FC<
  ModalTypes.ModalComponentProps<VisualAdjustmentsProps>
> = ({ closeFn, messageId, limit, chatId, projectId }) => {
  const dispatch = useDispatch();
  const visualizePrompt = useSelector(getVisualizePrompt);
  const ratio = useSelector(getOptions('ratio'));
  const style = useSelector(getOptions('style'));
  const isVisualAdjustments = useSelector(getIsVisualAdjustments);
  const profile = useSelector(getProfile);
  const visualizeLimit = useSelector(getVisualizeLimit);
  const textLine2 = useSelector(getVisualizeText);
  const { handle } = useTokensDisclaimer('visualizations');
  const closeModal = () => {
    closeFn();
    dispatch(projectsActions.clearVisualizePrompt());
  };

  useEffect(() => {
    dispatch(projectsActions.fetchVisualizePrompt({ messageId }));
  }, []);

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    closeModal();
    if (isVisualAdjustments !== payload.values.magic) {
      dispatch(profileActions.showProfileTooltip());
    }

    const enoughTokens =
      (profile?.tokens ?? 0) >= (profile?.token_visualization_price ?? 0);

    if (limit > 0 || enoughTokens) {
      handle();
      dispatch(
        profileActions.updateProfile({
          ...payload,
          values: { visual_adjustments: payload.values.magic },
        }),
      );
      dispatch(
        wsActions.sendMessageRequest({
          values: {
            action: 'request',
            app: 'chat',
            event: 'visualize',
            data: {
              project_id: projectId,
              chat_id: chatId,
              message_id: messageId,
              parameters: [payload.values.ratio, payload.values.style].filter(
                (item) => item !== 0,
              ),
              user_midjourney_prompt: payload.values.prompt,
            },
          },
        }),
      );
    } else {
      closeModal();
      if (profile?.subscription?.visualize_limits === 0 && !enoughTokens) {
        dispatch(
          modalActions.modal({
            component: 'LimitExhausted',
            forceClose: true,
            ...LimitExhaustedContent.buyTokens.text_line_2,
            limitText: textLine2,
            variant: 'secondary',
          }),
        );
      } else {
        dispatch(
          modalActions.modal({
            component: 'LimitExceeded',
            title: 'Info',
            forceClose: true,
            text: 'Visualizations / day',
            limit: visualizeLimit,
            description:
              profile?.subscription?.subscription_plan.code !==
              SubPlansCode.free
                ? `You have reached the limit of visualizations for today`
                : `You need to upgrade your subscription plan in order to continue using
            the chat`,
          }),
        );
      }
    }
  };

  return (
    <Box sx={styles.visualAdjustments}>
      <FinalForm
        initialValues={{
          prompt:
            visualizePrompt && visualizePrompt.messageId === messageId
              ? visualizePrompt.prompt
              : '',
          ratio: ratio[0].id,
          style: style[0].id,
          magic: isVisualAdjustments,
        }}
        keepDirtyOnReinitialize
        component={VisualAdjustmentsForm}
        extraProps={{
          isCurrentPrompt: visualizePrompt?.messageId === messageId,
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={closeModal}
        schema={schema}
      />
    </Box>
  );
};

export default VisualAdjustments;
