import { createAction } from '@reduxjs/toolkit';

import couponSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const couponActions = {
  ...couponSlice.actions,
  confirmCoupon: createAction(
    'coupon/confirmCoupon',
    prepareActions.movePromiseToMeta,
  ),
  addCoupon: createAction('coupon/addCoupon', prepareActions.movePromiseToMeta),
  // INJECT
};
