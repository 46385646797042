import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';
import {
  FillSubscriptionPlansActionPayload,
  ISubscriptionCoupon,
} from '@bus/subscription/typedefs';
import { getSubscriptionCoupon } from '@bus/profile/selectors';

export function* fetchSubscriptionPlans() {
  try {
    const coupon: ISubscriptionCoupon = yield effects.select(
      getSubscriptionCoupon,
    );
    yield effects.put(subscriptionActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'subscriptions/subscription-plans' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillSubscriptionPlansActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      subscriptionActions.fillSubscriptionPlans({ plans: data, coupon }),
    );
    yield effects.put(subscriptionActions.isSubscriptionsFetched());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.stopFetching());
  }
}
