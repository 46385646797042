import authSlice from '@bus/auth/slice';
import optionsSlice from '@bus/options/slice';
import profileSlice from '@bus/profile/slice';
import uiSlice from '@bus/ui/slice';
import modalSlice from '@core/Modal/state/slice';
import { combineReducers } from '@reduxjs/toolkit';
import projectsSlice from '@bus/projects/slice';
import chatSlice from '@bus/chat/slice';
import subscriptionSlice from '@bus/subscription/slice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistSlice from '@bus/persist/slice';
import savedMessagesSlice from '@bus/savedMessages/slice';
import settingsSlice from '@bus/settings/slice';
import aiConversationSlice from '@bus/aiConversation/slice';
import guidanceSlice from '@bus/guidance/slice';

import createdImagesSlice from '@bus/createdImages/slice';
import storyTellerSlice from '@bus/storyTeller/slice';
import wsSlice from '@bus/ws/slice';
import metametricsSlice from '@bus/metametrics/slice';
import couponSlice from '@bus/coupon/slice';
// IMPORTS

const persistConfig = {
  key: 'ui',
  storage,
};

const persistConfig2 = {
  key: 'persist',
  storage,
};

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  options: optionsSlice.reducer,
  ui: persistReducer(persistConfig, uiSlice.reducer),
  profile: profileSlice.reducer,
  modal: modalSlice.reducer,
  projects: projectsSlice.reducer,
  chat: chatSlice.reducer,
  subscription: subscriptionSlice.reducer,
  persist: persistReducer(persistConfig2, persistSlice.reducer),
  savedMessages: savedMessagesSlice.reducer,
  settings: settingsSlice.reducer,
  aiConversation: aiConversationSlice.reducer,
  guidance: guidanceSlice.reducer,
  createdImages: createdImagesSlice.reducer,
  storyTeller: storyTellerSlice.reducer,
  ws: wsSlice.reducer,
  metametrics: metametricsSlice.reducer,
  coupon: couponSlice.reducer,
  // INJECT
});
