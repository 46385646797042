import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { IProjects } from '@bus/projects/typedefs';
import { Grid } from '@components/common/Grid';
import DatasetItem from '@components/pages/private/HomePage/DatasetItem';
import TimeLeftToConversation from '@components/common/TimeLeftToConversation';
import ExperienceItem from '@components/pages/private/HomePage/ExperienceItem';
import { useDispatch, useSelector } from 'react-redux';
import { getNextSession } from '@bus/aiConversation/selectors';
import { book } from '@routes/book';
import StoryTellerDark from '@assets/StoryTellerDark.png';
import StoryTellerLight from '@assets/StoryTellerLight.png';
import FaceSwapDark from '@assets/faceSwapDark.png';
import FaceSwapLight from '@assets/faceSwapLight.png';
import MetametricsDark from '@assets/MetametricsDark.png';
import MetametricsLight from '@assets/MetametricsLight.png';
import ImageGenerationDark from '@assets/imageGenerationDark.png';
import ImageGenerationLight from '@assets/imageGenerationLight.png';
import ImageGalleryDark from '@assets/ImageGalleryDark.png';
import ImageGalleryLight from '@assets/ImageGalleryLight.png';
import FeatureItem from '@components/pages/private/HomePage/FeatureItem';
import { createdImagesActions } from '@bus/createdImages/actions';
import { modalActions } from '@core/Modal/state/actions';
import { SubPlansCode } from '@bus/subscription/typedefs';
import {
  getIsVisualisationsLimit,
  getProfile,
  getVisualizeLimit,
  getVisualizeText,
} from '@bus/profile/selectors';
import { getUploadedFaces } from '@bus/createdImages/selectors';
import { LimitExhaustedContent } from '@components/modals/LimitExhausted';

type MasterMenuProps = {
  list: IProjects[];
};

export const MasterMenu: FC<MasterMenuProps> = ({ list }) => {
  const nextSession = useSelector(getNextSession);
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const limit = useSelector(getIsVisualisationsLimit);
  const uploadedFaces = useSelector(getUploadedFaces);
  const visualizeLimit = useSelector(getVisualizeLimit);
  const textLine2 = useSelector(getVisualizeText);

  const handleOpenStudio = () => {
    const enoughTokens =
      (profile?.tokens ?? 0) >= (profile?.token_visualization_price ?? 0);

    if (limit || enoughTokens) {
      dispatch(createdImagesActions.openStudio());
    } else {
      if (profile?.subscription?.visualize_limits === 0 && !enoughTokens) {
        dispatch(
          modalActions.modal({
            component: 'LimitExhausted',
            forceClose: true,
            ...LimitExhaustedContent.buyTokens.text_line_2,
            limitText: textLine2,
            variant: 'secondary',
          }),
        );
      } else {
        dispatch(
          modalActions.modal({
            component: 'LimitExceeded',
            title: 'Info',
            forceClose: true,
            text: 'Visualizations / day',
            limit: visualizeLimit,
            description:
              profile?.subscription?.subscription_plan.code !==
              SubPlansCode.free
                ? `You have reached the limit of visualizations for today`
                : `You need to upgrade your subscription plan in order to continue using
            the chat`,
          }),
        );
      }
    }
  };

  const handleOpenWrapFace = () => {
    dispatch(
      createdImagesActions.createSwap({
        swap_image_url: uploadedFaces[0].image,
      }),
    );
    dispatch(
      createdImagesActions.openWrapStudio({
        status: true,
        targetImage: '',
      }),
    );
  };

  return (
    <Box sx={styles.datasetList}>
      <Box sx={styles.dataSetWrapper}>
        <Box sx={styles.datasetTitle} mb={'10px'}>
          <Typography
            variant={'subtitle1'}
            color={'text.primary'}
            fontWeight={500}>
            Worlds
          </Typography>
        </Box>
        <Grid container spacing={'16px'} height={'100%'}>
          {list.map((item, index) => (
            <Grid
              key={item.id}
              item
              sx={styles.item}
              // lg={6}
              md={6}
              xs={index < 2 ? 12 : 6}>
              <DatasetItem
                name={item.name}
                description={item.description}
                project={item}
                isPopular={item.name === 'Sibyl' || item.name === 'Ennealogy'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={styles.wrapper}>
        <Box mb={'10px'}>
          <Typography
            variant={'subtitle1'}
            color={'text.primary'}
            fontWeight={500}>
            Features
          </Typography>
        </Box>
        <Grid container spacing={'16px'} height={'100%'}>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <FeatureItem
              name={'Metametrics'}
              iconLight={MetametricsLight}
              iconDark={MetametricsDark}
              link={`${book.metametrics}`}
              handleClick={() => {}}
              isBeta
              description={
                'Advanced personal prediction and total self understanding.'
              }
            />
          </Grid>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <FeatureItem
              name={'Image Generation'}
              iconLight={ImageGenerationLight}
              iconDark={ImageGenerationDark}
              link={`${book.imageGallery}/self-generated`}
              handleClick={handleOpenStudio}
              description={
                'Create stunning imagery with text for your imagination.'
              }
            />
          </Grid>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <FeatureItem
              name={'Face Swap'}
              iconLight={FaceSwapLight}
              iconDark={FaceSwapDark}
              link={`${book.imageGallery}/face-swap`}
              handleClick={handleOpenWrapFace}
              description={
                'Project your face on to any image for realistic results.'
              }
            />
          </Grid>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <ExperienceItem
              name={'Storyteller'}
              iconLight={StoryTellerLight}
              iconDark={StoryTellerDark}
              link={book.storyTellerHome}
            />
          </Grid>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <FeatureItem
              name={'Image Gallery'}
              iconLight={ImageGalleryLight}
              iconDark={ImageGalleryDark}
              link={`${book.imageGallery}/0`}
              handleClick={() => {}}
              description={
                'Review, download, and share all your imagery to social media.'
              }
            />
          </Grid>
          <Grid item sx={styles.featureItem} xl={6} md={4} xs={6}>
            <TimeLeftToConversation targetDate={nextSession?.start_at ?? ''} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MasterMenu;
