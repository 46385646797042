import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const subscriptionSelector = (state: RootState) => state.subscription;

export const getIsSubscriptionFetching = createSelector(
  [subscriptionSelector],
  (result) => {
    return result.isFetching;
  },
);

export const getIsSubscriptionsFetching = createSelector(
  [subscriptionSelector],
  (result) => {
    return result.isSubscriptionsFetching;
  },
);

export const getIsSubscriptionCancelFetching = createSelector(
  [subscriptionSelector],
  ({ isSubscriptionCancelFetching }) => {
    return isSubscriptionCancelFetching;
  },
);

export const getSubscriptionPlansMonth = createSelector(
  [subscriptionSelector],
  (result) => {
    return result.subscriptionPlans.filter(
      (item) => item.interval_unit === 'monthly',
    );
  },
);

export const getSubscriptionPlansYear = createSelector(
  [subscriptionSelector],
  (result) => {
    return result.subscriptionPlans.filter(
      (item) => item.interval_unit === 'annual',
    );
  },
);

export const getBillingStatus = createSelector(
  [subscriptionSelector],
  (result) => {
    return result.billingStatus;
  },
);
