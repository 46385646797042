// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  CouponState,
  UpdateCouponActionPayload,
} from './typedefs';

const initialState: CouponState = {
  isFetching: false,
  coupon: null,
  result: '',
  error: '',
  isConfirmed: false,
  isAddedCouponApproved: '',
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    updateCoupon(state, action: PayloadAction<UpdateCouponActionPayload>) {
      state.coupon = action.payload;
    },
    fillResult(state, action: PayloadAction<string>) {
      state.result = action.payload;
    },
    fillError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setIsConfirmed(state, action: PayloadAction<boolean>) {
      state.isConfirmed = action.payload;
    },
    setIsAddedCouponApproved(state, action: PayloadAction<string>) {
      state.isAddedCouponApproved = action.payload;
    },
    clearCoupon(state) {
      state.coupon = null;
    },
    // INJECT
  },
});

export default couponSlice;
