import * as effects from 'redux-saga/effects';

import { couponActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  confirmCoupon,
  addCoupon,
} from './workers';
// IMPORTS

function* watchConfirmCoupon() {
  yield effects.takeEvery(couponActions.confirmCoupon.type, confirmCoupon);
}
function* watchAddCoupon() {
  yield effects.takeEvery(couponActions.addCoupon.type, addCoupon);
}
// WATCHERS
export function* watchCoupon() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchConfirmCoupon),
    effects.call(watchAddCoupon),
    // INJECT
  ]);
}
